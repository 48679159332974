<template>
  <div v-loading="accountStore.treasuryAccountTableLoading" class="elv-accounts-list-page-container">
    <AccountScreening
      ref="accountScreeningRef"
      tableType="Accounts"
      :exportText="$t('button.exportTreasuryAccount')"
      :auxiliaryTypeList="auxiliaryTypeList"
      @onChangeAccountFilter="onChangeAccountFilter"
    >
      <template #button>
        <Popover
          trigger="hover"
          :arrow="false"
          placement="bottomLeft"
          overlay-class-name="elv-accounts-header-main-right__operating-popover"
          :offset="2"
          @openChange="onOpenChangeBalancePopover"
        >
          <div class="elv-accounts-header-main-right-balance" style="padding-left: 12px">
            {{ t('common.balance') }}
            <SvgIcon
              width="14"
              height="14"
              name="arrow-down-account"
              fill="#838D95"
              style="margin-left: 4px; transition: transform 0.3s ease-in-out"
              :style="{ transform: rotate }"
            />
          </div>

          <template #content>
            <div class="elv-accounts-header-main-right-balance add" @click="onOpenBalanceDialog">
              <SvgIcon name="add-balance" width="14" height="14" fill="#838D95" />
              <span>{{ t('button.addBalance') }}</span>
            </div>
            <div
              class="elv-accounts-header-main-right-balance is-verify"
              :class="{ 'is-disabled': entityStore.entityDetail?.treasuryVerifyStatus === 'DOING' }"
              @click="onStartVerify"
            >
              <img
                v-if="entityStore.entityDetail?.treasuryVerifyStatus === 'DOING'"
                src="@/assets/img/reports/sources-sync-loading.png"
                alt="loading"
              />
              <div v-loading="verifyLoading">
                <SvgIcon
                  v-if="entityStore.entityDetail?.treasuryVerifyStatus !== 'DOING'"
                  name="Checkmark"
                  width="14"
                  height="14"
                  fill="#838D95"
                />
                <span>{{
                  entityStore.entityDetail?.treasuryVerifyStatus !== 'DOING'
                    ? t('report.verifyBalance')
                    : t('common.verifying')
                }}</span>
              </div>
            </div>
            <div class="elv-accounts-header-main-right-balance" @click="onStartRefreshBalance">
              <!-- :class="{ 'is-disabled': entityStore.entityDetail?.bulkRefreshBalanceStatus === 'DOING' }" -->
              <!-- <img
            v-if="entityStore.entityDetail?.bulkRefreshBalanceStatus === 'DOING'"
            src="@/assets/img/reports/sources-sync-loading.png"
            alt="loading"
          /> -->
              <div v-loading="refreshBalanceLoading">
                <!-- v-if="entityStore.entityDetail?.bulkRefreshBalanceStatus !== 'DOING'" -->
                <el-tooltip
                  effect="dark"
                  placement="top"
                  popper-class="elv-table-operating-refresh-balance-tips"
                  overlay-classname="elv-table-operating-refresh-balance-tips"
                  :show-after="500"
                  :disabled="refreshBalanceLoading"
                >
                  <div class="elv-accounts-header-main-right-balance-content">
                    <SvgIcon name="renew" width="14" height="14" fill="#838D95" />
                    <span>{{ t('button.refreshBalance') }}</span>
                  </div>
                  <template #content>
                    <div class="elv-table-operating-refresh-balance-tips-content">{{ tooltip }}</div>
                  </template>
                </el-tooltip>
                <!-- {{
              entityStore.entityDetail?.bulkRefreshBalanceStatus !== 'DOING'
                ? t('button.refreshBalance')
                : t('report.refreshingBalance')
            }} -->
              </div>
            </div>
          </template>
        </Popover>

        <elv-button
          class="elv-accounts-header-main-right-add"
          height="32"
          width="120"
          round
          plain
          type="primary"
          @click="onAddAccount"
        >
          <SvgIcon name="source-add" width="16" height="16" />{{ t('button.addAccount') }}</elv-button
        >
      </template>
    </AccountScreening>

    <ul
      v-if="entityStore.entityDetail?.progress?.entityAccountCsvUploadList?.length"
      class="elv-account-upload-status-list"
    >
      <li
        v-for="(item, index) in entityStore.entityDetail?.progress?.entityAccountCsvUploadList"
        :key="index"
        class="elv-account-upload-status-item"
      >
        <div class="elv-account-upload-status-item__state">
          <template v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)">
            <template v-if="item.status === 'FINISHED'">
              <SvgIcon class="success" name="checkmark-filled" width="18" height="18" fill="#94C300" />
              {{ t('title.fileImportCompleted', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
            </template>
            <template v-else>
              <SvgIcon class="success" name="sources-sync-error" width="18" height="18" fill="#94C300" />
              {{ t('title.fileImportFailed', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
            </template>
          </template>
          <template v-else>
            <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
            {{ t('title.fileIsImporting', { name: item?.entityFile?.name.split('.csv')?.[0] }) }}
          </template>
        </div>
        <template v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)">
          <p class="elv-account-upload-status-item__success">
            {{ t('common.successfully') }}: {{ formatNumber(item.successCount) }}
            {{ item.successCount > 1 ? t('common.items') : t('common.item') }}
          </p>
          <div v-if="item.failCount" class="elv-account-upload-status-item__failure">
            {{ t('common.failure') }}:&nbsp;
            <p>{{ formatNumber(item.failCount) }}</p>
            &nbsp;{{ item.failCount > 1 ? t('common.items') : t('common.item') }}
            <span v-if="item.failFileDownloadUrl" @click="onDownloadFailFile(item.failFileDownloadUrl)">{{
              t('button.clickDownloadAndReEdit')
            }}</span>
          </div>
        </template>
        <div
          v-if="['FINISHED', 'FAILED', 'FAIL'].includes(item.status)"
          v-loading="closeLoading && currentFileData.entityAccountCsvUploadId === item.entityAccountCsvUploadId"
          class="elv-account-upload-status-item__close"
        >
          <SvgIcon name="common-close" width="18" height="18" fill="#838D95" @click="onCloseUploadStatus(item)" />
        </div>
      </li>
    </ul>

    <div :style="{ height: tableHeight }">
      <template v-if="!accountStore.treasuryAccountTableLoading">
        <TableList
          ref="processTableRef"
          type="accounts"
          :status="dataStatus"
          groupName="platformType"
          :height="tableHeight"
          :dateTime="accountScreeningRef?.dateTimeValue"
        />
      </template>
    </div>
    <AccountDialog ref="accountsDialogRef" model="add" :currentData="{}" />
    <BalanceListDialog ref="balanceListDialogRef" :subGroup="accountScreeningRef?.subGroup ?? 'NONE'" />
    <LimitReachedDialog
      ref="limitReachedDialogRef"
      limitType="account"
      @onConfirmUpgradeProjectPlan="onConfirmUpgradeProjectPlan"
    />
    <UpgradeProjectPlanDialog ref="upgradeProjectPlanDialogRef" />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { $t } from '@/i18n'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import LedgerApi from '@/api/LedgerApi'
import { Popover } from 'ant-design-vue'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import AccountsApi from '@/api/AccountsApi'
import { downloadFile } from '@/lib/download'
import TableList from './components/TableList.vue'
import { ProjectSubscribeStatus } from '#/ProjectTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'
import AccountScreening from '../components/Screening.vue'
import AccountDialog from './components/AccountDialog.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import BalanceListDialog from './components/BalanceListDialog.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'
import LimitReachedDialog from '@/pages/Financials/components/LimitReachedDialog.vue'
import UpgradeProjectPlanDialog from '@/pages/Financials/ProjectSetting/components/UpgradeProjectPlanDialog.vue'

const { t } = useI18n()
const route = useRoute()

const dataStatus = ref('')
const verifying = ref(false)
const transactionStatus = ref('FINISHED')
const rotate = ref('rotate(0deg)')
const verifyLoading = ref(false)
const auxiliaryTypeList: any = ref([])
const refreshBalanceLoading = ref(false)
const isImportAccount = ref(false)
const closeLoading = ref(false)
const currentFileData = ref<any>({})

const globalStore = useGlobalStore()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const projectStore = useProjectStore()
const tableConfigStore = useReportsTableConfigStore()
const processTableRef = useTemplateRef('processTableRef')
const accountsDialogRef = useTemplateRef('accountsDialogRef')
const limitReachedDialogRef = useTemplateRef('limitReachedDialogRef')
const upgradeProjectPlanDialogRef = useTemplateRef('upgradeProjectPlanDialogRef')
const balanceListDialogRef = useTemplateRef('balanceListDialogRef')
const accountScreeningRef = useTemplateRef<InstanceType<typeof AccountScreening>>('accountScreeningRef')

const tableHeight = computed(() => {
  return `calc(100% - 6px - ${
    entityStore.entityDetail?.progress?.entityAccountCsvUploadList?.length
      ? (entityStore.entityDetail?.progress?.entityAccountCsvUploadList?.length ?? 0) * 50 + 4
      : 0
  }px)`
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const projectDetail = computed(() => {
  if (!isEmpty(projectStore.projectDetail)) return projectStore.projectDetail
  return globalStore.projectDetail(entityStore.entityDetail?.projectId)
})

const tooltip = computed(() => {
  return t('report.refreshBalanceInfo', {
    total: projectDetail.value?.plan !== 'VIP' ? 100 : 1000,
    left: entityStore.entityDetail?.refreshBalanceLeft
  })
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onConfirmUpgradeProjectPlan = () => {
  upgradeProjectPlanDialogRef.value?.changeUpgradeProjectDialogStatus()
}

const onAddAccount = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (
    projectDetail.value?.subscribeStatus === ProjectSubscribeStatus.EXCEEDED ||
    projectDetail.value?.txCountStatus === ProjectSubscribeStatus.EXCEEDED
  ) {
    limitReachedDialogRef.value?.changeLimitReachedDialogStatus()
    return
  }
  accountsDialogRef.value?.onCheckAccountDialog()
}

/**
 * @description: 余额按钮下拉状态改变
 * @param {boolean} visible
 */
const onOpenChangeBalancePopover = (visible: boolean) => {
  rotate.value = visible ? 'rotate(180deg)' : 'rotate(0deg)'
}

const onOpenBalanceDialog = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.balanceAdd
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (!accountStore.treasuryBalanceList.list.length) {
    return
  }
  balanceListDialogRef.value?.onCheckBalanceListDialog()
}

const onChangeAccountFilter = async () => {
  try {
    accountStore.treasuryAccountTableLoading = true
    const date = accountScreeningRef.value?.dateTimeValue
    const subGroup = accountScreeningRef.value?.subGroup
    const sort = accountScreeningRef.value?.accountSort
    const params: any = {
      date,
      subGroup,
      sort
    }
    if (!['NONE', 'PLATFORM'].includes(params.subGroup) && auxiliaryTypeList.value.length) {
      const auxiliaryType: any =
        auxiliaryTypeList.value.find((item: any) => {
          return item.auxiliaryTypeId === params.subGroup
        }) ?? {}
      if (auxiliaryType?.type) {
        params.subGroup = auxiliaryType?.type === 'ITEM' ? 'AUXILIARY_ITEM' : auxiliaryType?.type
        params.auxiliaryTypeId = auxiliaryType?.auxiliaryTypeId
      }
    }
    accountStore.setDatetime(date)
    await accountStore.fetchEntityRecentlyBalanceList(entityId.value, params)
    dataStatus.value = 'success'
    await tableConfigStore.getProcessTableConfig('accounts', ['', 'accountName', 'Datasource provider'], 'left', params)
    processTableRef.value?.resetList()
  } catch (error: any) {
    console.log(error)
    dataStatus.value = 'failed'
  } finally {
    accountStore.treasuryAccountTableLoading = false
  }
}

const onDownloadFailFile = (failFileDownloadUrl: string) => {
  downloadFile(failFileDownloadUrl)
}

const onCloseUploadStatus = async (row: any) => {
  try {
    currentFileData.value = row
    closeLoading.value = true
    await AccountsApi.closeEntityAccountCsvUploadStatus(entityId.value, row.entityAccountCsvUploadId)
    ElMessage.success(t('message.success'))
    entityStore.fetchEntityDetail(entityId.value)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    closeLoading.value = false
  }
}

const onStartVerify = async () => {
  try {
    if (!accountStore.treasuryBalanceList.list.length || entityStore.entityDetail?.treasuryVerifyStatus === 'DOING') {
      return
    }
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.entityAccount?.balanceVerify &&
      !verifying.value
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
      ElMessage.warning(t('message.TransactionInProgress'))
      return
    }
    verifyLoading.value = true
    await AccountsApi.executeTreasuryBalanceVerify(entityId.value)
    verifying.value = true
    await entityStore.fetchEntityDetail(entityId.value)
    ElMessage.success(t('message.refreshBalanceStarted'))
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    verifyLoading.value = false
  }
}

const getAuxiliaryTypeList = async () => {
  try {
    const { data } = await LedgerApi.getAuxiliaryTypeList(entityId.value, { page: 1, limit: 100 })
    auxiliaryTypeList.value = data.list
  } catch (error) {
    console.error(error)
  }
}

const onStartRefreshBalance = async () => {
  try {
    if (!accountStore.treasuryBalanceList.list.length) {
      return
    }
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.entityAccount?.balanceVerify
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
      ElMessage.warning(t('message.TransactionInProgress'))
      return
    }
    refreshBalanceLoading.value = true
    await AccountsApi.refreshAllTreasuryBalance(entityId.value)
    await entityStore.fetchEntityDetail(entityId.value)
    ElMessage.success(t('message.BalanceVerificationStarted'))
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    refreshBalanceLoading.value = false
  }
}

watch(
  () => route,
  async () => {
    if (route.name === 'entity-accounts-treasury') {
      try {
        accountStore.treasuryAccountTableLoading = true
        await getAuxiliaryTypeList()
        accountStore.treasuryBalanceList = {
          list: [],
          total: {
            currencyList: [],
            totalFC: 0
          }
        }
        tableConfigStore.processTableConfig = []
        const accountFilter: any =
          accountStore.accountFilterList.find((item: any) => {
            return item.entityId === entityId.value
          }) ?? {}
        const params = {
          date: dayjs().tz(entityStore.entityDetail?.timezone).format('YYYY-MM-DD'),
          subGroup: 'NONE',
          sort: 'BALANCE',
          ...accountFilter?.data
        }
        if (!['NONE', 'PLATFORM'].includes(params.subGroup) && accountFilter?.data?.auxiliaryType?.type) {
          const auxiliaryType = auxiliaryTypeList.value.find(
            (item: any) => item.auxiliaryTypeId === accountFilter?.data?.auxiliaryType?.auxiliaryTypeId
          )
          if (!isEmpty(auxiliaryType)) {
            params.subGroup = accountFilter?.data?.auxiliaryType?.type
            params.auxiliaryTypeId = accountFilter?.data?.auxiliaryType?.auxiliaryTypeId
            delete params.auxiliaryType
          } else {
            params.subGroup = 'NONE'
          }
        }
        accountStore.setDatetime(params.date)
        await accountStore.fetchEntityRecentlyBalanceList(entityId.value, params)
        accountStore.treasuryAccountTableLoading = false
        if (route.name === 'entity-accounts-treasury') {
          await tableConfigStore.getProcessTableConfig(
            'accounts',
            ['', 'accountName', 'Datasource provider'],
            'left',
            params
          )
          dataStatus.value = 'success'
          accountStore.treasuryAccountTableLoading = false
          // processTableRef.value?.resetList()
        }
      } catch (error) {
        console.log(error)
        if (route.name === 'entity-accounts-treasury') {
          dataStatus.value = 'failed'
          await tableConfigStore.getProcessTableConfig('accounts', ['', 'accountName', 'Datasource provider'], 'left')
        }
      } finally {
        accountStore.treasuryAccountTableLoading = false
      }
    }
  },
  { immediate: true, deep: true }
)

watch(
  () => entityStore.entityDetail,
  async (newValue, oldValue) => {
    const importStatus = entityStore.entityDetail?.progress?.entityAccountCsvUploadList?.some((item: any) => {
      if (item.status === 'WAITING') return true
      return false
    })

    if (newValue.entityId !== oldValue?.entityId) {
      transactionStatus.value = entityStore.entityDetail?.progress.transactionStatus ?? 'FINISHED'
      verifying.value = entityStore.entityDetail?.treasuryVerifyStatus === 'DOING'
      isImportAccount.value = importStatus
    }
    if (
      verifying.value &&
      transactionStatus.value !== 'DOING' &&
      entityStore.entityDetail?.treasuryVerifyStatus === 'DONE'
    ) {
      ElMessage.success(t('message.BalanceVerificationCompleted'))
      accountStore.treasuryAccountTableLoading = true
      processTableRef.value?.resetList()
      accountStore.treasuryAccountTableLoading = false
    }
    if (transactionStatus.value === 'DOING' && entityStore.entityDetail?.progress.transactionStatus === 'FINISHED') {
      ElMessage.success(t('message.sourceSynchronizationIsCompleted'))
      if (processTableRef.value?.isShowAccountOverlayDrawer) {
        await processTableRef.value?.updateAccountDetail()
      }
      processTableRef.value?.resetList()
    }
    verifying.value = entityStore.entityDetail?.treasuryVerifyStatus === 'DOING'
    transactionStatus.value = entityStore.entityDetail?.progress?.transactionStatus ?? 'FINISHED'

    if (
      entityStore.entityDetail?.entityId === entityId.value &&
      route?.name === 'entity-accounts-treasury' &&
      entityStore.entityDetail?.progress?.entityAccountCsvUploadList?.length
    ) {
      if (isImportAccount.value && !importStatus) {
        processTableRef.value?.resetList()
      }
      isImportAccount.value = importStatus
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  isImportAccount.value = false
  tableConfigStore.processTableConfig = []
  accountStore.agGridApi = null
  accountStore.agGridColumnApi = null
})
</script>

<style lang="scss" scoped>
.elv-accounts-list-page-container {
  width: 100%;
  height: calc(100% - 90px);
  position: relative;
}

:deep(.elv-financial-process-table) {
  padding-top: 0px;
}

.elv-accounts-header-main-right-balance {
  margin-left: 12px;
  height: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #636b75;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  white-space: nowrap;

  .elv-accounts-header-main-right-balance-content {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 6px;
  }

  &.add {
    margin-right: 8px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  &.is-verify {
    margin-right: 8px;

    &::before {
      width: 0px;
      height: 0px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 14px;
    background: #dde1e6;
  }

  &:not(.is-disabled):hover {
    color: #1343bf;

    svg {
      fill: #1343bf;
    }
  }

  &.is-disabled {
    cursor: not-allowed;
  }

  img {
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 6px;
    animation: loading-rotate 2s linear infinite;
  }

  :deep(.el-loading-spinner) {
    position: relative;

    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 12px;
    }
  }
}

.elv-accounts-header-main-right-add {
  position: absolute;
  right: 24px;
  top: -41px;
}
</style>

<style lang="scss">
.elv-account-upload-status-list {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px 0 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;

  .elv-account-upload-status-item {
    width: 100%;
    height: 38px;
    display: flex;
    box-sizing: border-box;
    padding: 10px 8px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #abc0f5;
    background: rgba(229, 237, 255, 0.2);
    gap: 32px;
    position: relative;

    .elv-account-upload-status-item__state {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      img {
        width: 18px;
        height: 18px;
        display: block;
        animation: loading-rotate 2s linear infinite;
      }
    }

    .elv-account-upload-status-item__success {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .elv-account-upload-status-item__failure {
      display: flex;
      align-items: center;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      p {
        color: #df2200;
      }

      span {
        color: #1753eb;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: lowercase;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .elv-account-upload-status-item__close {
      position: absolute;
      right: 8px;
      cursor: pointer;

      &:hover svg {
        fill: #1343bf;
      }

      .el-loading-mask {
        .circular {
          width: 28px;
          height: 28px;
          position: relative;
          top: 6px;
          left: -6px;
        }
      }
    }
  }
}

.ant-popover.elv-accounts-header-main-right__operating-popover {
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  background: #fff;
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 0px rgba(0, 0, 0, 0.05);

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0px;
    box-shadow: none;
    width: 134px;
    border-radius: 6px;
  }

  .elv-accounts-header-main-right-balance {
    display: flex;
    height: 35px;
    width: 100%;
    padding: 10px 12px;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    transition: background 0.1s;
    margin-left: 0;

    span {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 4px;
    }

    &.is-disabled {
      color: #aaafb6;
      cursor: not-allowed;
    }

    &:hover {
      background: #f9fafb;
    }
  }
}
</style>
